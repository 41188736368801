import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";
import {
  MainContainer,
  ContentWrapper,
  MapImage,
  AddressRow,
  Address,
  AddressHeader,
  AddressBlock,
  PageHeader,
  AddressItem,
  AddressSubHeader,
  Subtext,
  LeftSection,
  RightSection,
} from "../page-components/contact/styles";
import ContactForm from "../page-components/contact/ContactForm/ContactForm";

export default function ContactPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.CONTACT}>
      <Seo
        title="Contact | Robotic Imaging"
        description='"Robotic Imaging is here to accelerate your architectural projects. Contact us to help streamline your process."'
      />

      <header className="site__header"></header>

      <MainContainer id="Main Container">
        <PageHeader>Let's Chat!</PageHeader>
        <Subtext>
          Have a project in mind that you think we’d be a great fit for? Shoot
          us a message and get a free quote!
        </Subtext>
        <ContentWrapper id="Content Wrapper">
          <LeftSection id="Left Section">
            <MapImage
              id="Map Image"
              src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fcontact%2Fmap%20Laptop%2002.png?alt=media&token=432bbb10-68e2-41c2-ba61-72ec610dc194"
              alt="Map of Robotic Imaging Site Surveys"
            />
          </LeftSection>
          <RightSection id="Right Section">
            <ContactForm />
            <AddressRow>
              <AddressBlock id="Philadelphia">
                <AddressHeader>Corporate Mailing Address</AddressHeader>
                <AddressItem>
                  <AddressSubHeader>Robotic Imaging, Inc.</AddressSubHeader>
                  <Address>2401 Walnut St, Suite 101, </Address>
                  <Address>Philadelphia, PA 19103</Address>
                </AddressItem>
              </AddressBlock>
            </AddressRow>
          </RightSection>
        </ContentWrapper>
      </MainContainer>
    </SiteWrapper>
  );
}
