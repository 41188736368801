import React from "react";
import axios from "axios";
import Recaptcha from "react-google-recaptcha";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { logAnalytics } from "../../../firebase";
import { Box, Typography } from "@mui/material";
import {
  FormWrapper,
  RecaptchaContainer,
  StyledButton,
  StyledFormikTextField,
  StyledMenuItem,
  TopRow,
} from "./ContactForm.styles";
import { mailingList } from "../../../utils/vars";

declare module "yup" {
  interface ArraySchema<T> {
    unique(
      this: Yup.ArraySchema<Yup.AnySchema>,
      message: string,
      mapper?: (a: T) => any
    ): Yup.ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a: any) => a) {
  return this.test("unique", message, function (list) {
    const context: Yup.TestContext = this; // Provides access to the context
    if (!Array.isArray(list)) {
      return this.createError({ path: context.path, message: message });
    }
    const mappedList = list.map(mapper);
    const isUnique = mappedList.length === new Set(mappedList).size;
    return (
      isUnique || this.createError({ path: context.path, message: message })
    );
  });
});

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(2, "Enter your full name").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  recaptcha: Yup.string()
    .nullable()
    .required("Please confirm that you are not a robot"),
});

export default function ContactForm({}) {
  const topics = ["New Project", "Joining Our Team", "General Inquiries"];
  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        topic: "",
        message: "",
        recaptcha: "",
        success: false,
      }}
      validationSchema={SignupSchema}
      onSubmit={(
        { name, phone, email, topic, message },
        { setFieldValue, resetForm }
      ) => {
        logAnalytics("requested_quote");

        try {
          // Send request info to formium
          axios({
            method: "POST",
            url: `https://api.formium.io/submit/604bcdd9edd5310001df915b/requestascan`,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              name,
              phone,
              email,
              topic,
              message,
            },
          });

          // Email
          axios.post(
            `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMail`,
            {
              destination: email,
              bcc: ["info@roboticimaging.com", ...mailingList],
              subject: "New Contact",
              text: `Thanks for reaching out to Robotic Imaging! We look forward to connecting!

                      Here's a copy of the information you've sent to us:
                      Name: ${name}
                      Phone: ${phone}
                      Email Address: ${email}
                      Topic: ${topic}
                      Message: ${message}
                      `,
              html: `
                      <div>Thanks for reaching out to Robotic Imaging! We look forward to connecting!</div>
                      <br />
                      <div>Here's a copy of the information you've sent to us:</div>
                          <div>Name: ${name}</div>
                          <div>Phone: ${phone}</div>
                          <div>Email Address: ${email}</div>
                          <div>Topic: ${topic}</div>
                          <div>Message: ${message}</div>
                      `,
            }
          );
          setFieldValue("success", true);
          setTimeout(() => resetForm(), 6000);
        } catch (err) {
          setFieldValue("success", false);
          console.log(err);
        }
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => (
        <Form style={{ width: "100%" }}>
         <Box my={4} style={{ width: "100%" }}>
         <FormWrapper>
          <TopRow>
               <StyledFormikTextField
                 name="name"
                 fullWidth
                 label="Name"
               />
               <StyledFormikTextField
                 name="phone"
                 fullWidth
                 label="Phone"
               />
             </TopRow>
             <StyledFormikTextField
               name="email"
               fullWidth
               label="E-mail"
             />
             <StyledFormikTextField
               name="topic"
               fullWidth
               label="Topic"
               select
             >
               {topics.map((topic) => (
                 <StyledMenuItem key={topic} value={topic}>
                   {topic}
                 </StyledMenuItem>
               ))}
             </StyledFormikTextField>
             <StyledFormikTextField
               name="message"
               fullWidth
               label="Message"
               multiline
               rows={4}
             />
             <RecaptchaContainer width={null}>
               <Field name="recaptcha">
                 {({ field }: { field: any }) => (
                   <Recaptcha
                     {...field}
                     sitekey={process.env.GATSBY_recaptchaSiteKey}
                     onChange={(value: string) => {
                       setFieldValue("recaptcha", value);
                     }}
                   />
                 )}
               </Field>
               <StyledButton
                 type="submit"
                 variant="contained"
                 style={{ height: "76px", width: "100%" }}
               >
                 Submit
               </StyledButton>
             </RecaptchaContainer>
             {errors.recaptcha && touched.recaptcha && (
               <Typography color="error" textAlign={"center"}>
                 {errors.recaptcha}
               </Typography>
             )}
             {values.success && (
               <Typography color="primary" textAlign={"center"}>
                 Form submitted successfully! Resetting form fields in 6
                 seconds...
               </Typography>
             )}
             </FormWrapper>
       </Box>
       </Form>

      )}
    </Formik>
  );
}
