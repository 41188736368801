import { Button, MenuItem, styled, TextField } from "@mui/material";
import { breakpoint } from "../../../components/styled-components/Breakpoints/breakpoints";
import { Field, FieldProps } from "formik";
import React from "react";

export const StyledTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInputLabel-root": {
    color: "#ffb310",
    fontSize: "0.8vw",
    "@media (max-width: 989px)": {
      fontSize: "12px",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#ffb310",
    opacity: 1,
    fontSize: "1vw",

    "@media (max-width: 989px)": {
      fontSize: "14px",
    },
  },
  "& .MuiInputLabel-shrink": {
    color: "#ffb310",
  },
  "& .Mui-focused .MuiInputLabel-root": {
    color: "#ffb310",
  },
  "& .MuiInputBase-input": {
    color: "#f6f6f6",
    fontSize: "1vw",
    "@media (max-width: 989px)": {
      fontSize: "14px",
    },
  },
  "& .Mui-focused .MuiInputBase-input::placeholder": {
    color: "#ffb310",
    opacity: 1,
  },
  "& .MuiInput-underline.Mui-focused:after": {
    borderBottomColor: "#ffb310",
  },
  "& .MuiInput-underline.Mui-focused:before": {
    borderBottomColor: "#ffb310",
  },
  "&:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 1000px #1e1e1e inset !important",
    "-webkit-text-fill-color": "#f6f6f6 !important",
    backgroundColor: "black !important",
  },
});

export const StyledButton = styled(Button)`
  background-color: #ffb310;
  text-transform: none;
  color: black;
  font-size: 1.5vw;
  width: 100%;
  height: 75px;
  align-self: flex-start;
  max-width: 304px;

  &:hover {
    background-color: #e69d0c;
  }

  ${breakpoint(
    "medium-",
    `
      justify-content: center;
      height: auto;
      font-size: 18px;
      align-self: center;
    `
  )}
`;

export const TopRow = styled("div")`
  display: flex;
  width: 100%;
  gap: 15px;

  ${breakpoint(
    "medium-",
    `
   flex-direction: column;
  `
  )}
`;

export const FormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const RecaptchaContainer = styled("div")<{ width: number | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  
  ${breakpoint(
    "medium-",
    `
      flex-direction: column;
    `
  )}
`;

export const StyledMenuItem = styled(MenuItem)({
  fontSize: "1vw",
  "@media (max-width: 989px)": {
    fontSize: "14px",
  },
});

interface StyledFormikTextFieldProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  multiline?: boolean;
  rows?: number;
  select?: boolean;
  children?: React.ReactNode;
}

export const StyledFormikTextField: React.FC<StyledFormikTextFieldProps> = ({
  label,
  ...props
}) => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps) => (
      <StyledTextField
        {...field}
        {...props}
        label={label}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        variant="standard"
        InputLabelProps={{ shrink: !!field.value }}
      />
    )}
  </Field>
);